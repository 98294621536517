import React from 'react'
import styles from './dApps.module.css'
import { Button, Header } from '../../components'

export const DAppsStart = () => {
	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<Header title='dApps' />

				<div className={styles.logo_block}>
					<div className={styles.title}>No dApp connected yet.</div>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<p className={styles.par}>Your Ordinals Wallet is compatible with WalletConnect.</p>
				<Button variant='default' to='/dapps-connect'>
					Connect
				</Button>
			</div>
		</div>
	)
}
