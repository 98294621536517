import React from 'react'
import styles from './welcome.module.css'
import {Button} from '../../components'
import {Link} from 'react-router-dom'
import {StepProgress} from '../../components'

import logo from '../../assets/images/logo.svg'
import coins from '../../assets/images/coins3d.png'

export const Step3 = () => {
    return (
        <div className='page'>
			<Link to='/start' className={styles.skipLink}>
				Skip
			</Link>
			<StepProgress active={3} activeLast="activeLast"/>
            <div className='page-inner' style={{paddingTop: 0}}>
                <div className={styles.logo}>
                    <img src={logo} className={styles.smallLogo} alt='Ordinals Wallet'/>
                    <div className={styles.title} style={{marginBottom: 10}}>
                        Access the world
                        <br/>
                        of cryto & DeFi
                    </div>
                    <img src={coins} alt="available coins" style={{width: '90%'}} />
                </div>
            </div>
            <div className='page-inner page-align-bottom'>
                <Button variant='default' to='/start'>
                    Continue
                </Button>
            </div>
        </div>
    )
}
