import React from 'react'
import styles from './dApps.module.css'
import QRCode from 'react-qr-code'
import { Header } from '../../components'

export const DApps = () => {
	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<Header title='dApps' />

				<div className={styles.block}>
					<div className={styles.qr}>
						<QRCode
							bgColor='var(--primary)'
							fgColor='var(--white)'
							size={255}
							style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
							viewBox={`0 0 256 256`}
							value='Coming soon!'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
