import React, { useState } from 'react'
import styles from './ItemSelect.module.css'
import Svg from '../../svgs/Svg'
import classNames from 'classnames/bind'
import { Box } from '../../components'

export const ItemSelect = ({
	title,
	subtitle,
	accountType,
	dropdownList,
	text,
	setNetwork,
}) => {
	const [status, setStatus] = useState(false)

	const itemClasses = classNames(styles.icon, status ? styles.opened : '')

	const handleClick = () => {
		if (dropdownList || text) {
			setStatus(!status)
		}
	}

	const onChooseNetwork = (title) => {
		setNetwork(title)
		setStatus(false)
	}

	return (
		<Box className={accountType ? styles.box_any : ''}>
			<div className={styles.trigger} onClick={handleClick}>
				<div className={styles.text}>
					<div className={styles.heading}>{subtitle}</div>
					<div className={styles.title}>{title}</div>
				</div>
				{dropdownList || text ? (
					<div className={itemClasses}>
						<Svg type='item-arrow' className='dropdown-icon' />
					</div>
				) : (
					''
				)}
			</div>
			{status && dropdownList ? (
				<div className={styles.dropdown}>
					{dropdownList.map((item, index) => (
						<div
							onClick={() => onChooseNetwork(item.title)}
							className={classNames(
								styles.dropdown_item,
								item.active ? styles.active : ''
							)}
							style={{ color: item.color }}
							key={index}>
							{item.title}
						</div>
					))}
				</div>
			) : status && text ? (
				<div className={styles.content_text}>{text}</div>
			) : (
				''
			)}
		</Box>
	)
}
