import React from 'react'
import styles from './account.module.css'
import { Menu } from '../../components'
import Svg from '../../svgs/Svg'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Account = () => {
	const navigate = useNavigate()
	const { backup } = useSelector((state) => state.storage)
	const list = [
		{
			title: 'Wallet',
			to: '/wallets',
			icon: 'wallet'
		},
		{
			title: 'Import existing wallet',
			to: '/import-start',
			icon: 'import'
		},
		{
			title: 'Backup',
			status: 'success',
			to: '/backup',
			icon: 'backup'
		},
		{
			title: 'Security',
			to: '/settings',
			icon: 'lock'
		},
		{
			title: 'Notifications',
			to: '/notifications',
			icon: 'notification'
		},
		// {
		// 	title: 'Currency',
		// 	to: '/currency',
		// },
		{
			title: 'RPC Node ',
			to: '/rpc',
			icon: 'rpc'
		},
		{
			title: 'Wallet Connect',
			to: '/dapps',
			icon: 'connect-wallet'
		},
		{
			title: 'About',
			to: '/about',
			icon: 'alert'
		},
	]

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className='title'>Account Settings</div>
				</div>

				<div className={styles.list}>
					{list.map((item, index) => (
						<div
							className={styles.item}
							key={index}
							onClick={() => navigate(item.to)}>
							<span style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
								<Svg type={item.icon}/>
								{item.title}
							</span>
							{item.status &&
								(item.status === 'success' && backup ? (
									<Svg type='success' />
								) : item.status === 'success' && !backup ? (
									<Svg type='error' />
								) : (
									''
								))}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
