import React from 'react'
import styles from './account.module.css'
import { Button, Tags } from '../../components'
import Svg from '../../svgs/Svg'
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'
import { Header } from '../../components/Header/Header';
const kitkat = 'Qsx@ah&OR82WX9T6gCt'

export const PrivateKey = () => {
	const { dataUser, currentWallet } = useSelector((state) => state.storage)
	const [tags, setTags] = React.useState([])

	React.useEffect(() => {
		if (dataUser.length) {
			const currentWalletData = dataUser.find(
				(item) => item.name == currentWallet
			)
			if (currentWalletData !== undefined) {
				setTags(
					CryptoJS.AES.decrypt(currentWalletData.privateKey, kitkat)
						.toString(CryptoJS.enc.Utf8)
						.split(' ')
				)
			} else {
				setTags([])
			}
		}
	}, [dataUser, currentWallet])

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Your private key' to='/wallets'/>
				<Tags content={tags} copy />
				<div className={styles.warning}>
					<Svg type='alert-triangle' />
					Never share recovery phrase with anyone,<br/> store it securely!
				</div>
			</div>
		</div>
	)
}
