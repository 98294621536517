import React from 'react'
import styles from './send.module.css'
import { Button, Header, Keyboard, Menu, PriceInput } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setChooseCoinOne } from '../../redux/slices/TransactionsSlice'
import { ItemLoader } from './../../components/Loaders/ItemLoader'



export const Buy = () => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const { chooseCoinOne } = useSelector((state) => state.transaction)
	const [amountBuy, setAmountBuy] = React.useState('')

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
		}
	}, [state])

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner'>
				<div className='page-header'>
					<div className='title' style={{textAlign: 'center', width: '100%'}}>Add Cash</div>
				</div>

				{chooseCoinOne !== null ? (
					<div className={styles.wrapCoin}>
						<span className={styles.coinPrice}>${amountBuy !== '' ? amountBuy : '0'}</span>
						<div className={styles.row}>
							<h5 className={styles.coinTitle}>{chooseCoinOne.name}</h5>
						</div>
					</div>
				) : (
					<ItemLoader style={{ marginTop: 20 }} />
				)}
				<Keyboard style={{marginTop: 40}} setValue={setAmountBuy} value={amountBuy} />
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					variant={amountBuy !== '' && amountBuy > 0 ? 'default' : 'disabled'}
					onClick={() => window.open('https://www.moonpay.com/buy')}>
					Buy
				</Button>
			</div>
		</div>
	)
}
