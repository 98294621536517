import React from 'react'
import {
	Button,
	Menu,
	ItemSelect,
	PriceInputGroup,
	TextRowsBlock
} from '../../components'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
	setChooseCoinOne,
	setChooseCoinTwo,
} from '../../redux/slices/TransactionsSlice'
import { useDispatch } from 'react-redux'
import styles from './swap.module.css'

export const Swap = () => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const { chooseCoinOne, chooseCoinTwo } = useSelector(
		(state) => state.transaction
	)
	const [value, setValue] = React.useState('')
	const [valueOut, setValueOut] = React.useState('')
	const [network, setNetwork] = React.useState('Bitcoin')
	React.useEffect(() => {
		if (value !== '') {
			const usdCount = chooseCoinOne.market_data.current_price * +value
			setValueOut(usdCount / chooseCoinTwo.market_data.current_price)
		} else {
			setValueOut('')
		}
	}, [value])

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
			if (state.from == 'swapTwo') {
				dispatch(setChooseCoinTwo(state.item))
			}
		}
	}, [state])

	const onSwap = () => {
		if (chooseCoinOne !== null && chooseCoinTwo !== null) {
			const frst = chooseCoinOne
			const scnd = chooseCoinTwo
			dispatch(setChooseCoinOne(scnd))
			dispatch(setChooseCoinTwo(frst))
		}
	}

	const mockList = [
		{
			title: 'Blockchain Network',
			active: true,
		},
		{
			title: 'Ethereum Mainnet',
		},
		{
			title: 'BNB Smart Chain',
		},
		{
			title: 'Avalanche Network',
		},
		{
			title: 'Arbitrum Internal',
		},
	]

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner'>
				<ItemSelect
					setNetwork={setNetwork}
					title={network}
					subtitle='Swap in:'
					dropdownList={mockList}
				/>
				<div>
					<PriceInputGroup
						valueTwo={valueOut}
						valueOne={value}
						setValueOne={setValue}
						onSwap={onSwap}
						chooseItemOne={chooseCoinOne}
						chooseItemTwo={chooseCoinTwo}
					/>
				</div>
				<TextRowsBlock style={{marginTop: 20}}/>
				<div className={styles.footer}>
					Ordinals Wallet cannot recover any lost funds.
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button variant='default' icon='change'>
					Swap
				</Button>
			</div>
		</div>
	)
}
