import React from 'react'
import styles from './settings.module.css'
import { Checkbox, ItemBlock, Header } from '../../components'

export const SettingsCurrency = () => {
	const list = [
		{
			title: 'USD - United States Dollar',
		},
		{
			title: 'BTC',
		},
		{
			title: 'EUR - Euro',
		},
		{
			title: 'GBP - British Pound Sterling',
		},
		{
			title: 'CAD - Canadian Dollar',
		},
		{
			title: 'JPY - Japanese Yen',
		},
		{
			title: 'CNY - Chinese Yuan',
		},
	]

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header title='Currency' />

				<div className={styles.list}>
					{list.map((item, index) => (
						<ItemBlock content={item} key={index}>
							<Checkbox
								type='radio'
								variant='boxed_inline'
								id={`currency` + index}
								name='currency-list'
							/>
						</ItemBlock>
					))}
				</div>
			</div>
		</div>
	)
}
