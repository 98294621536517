import React from 'react';
import styles from './ImageBlock.module.css'

export const ImageBlock = ({ title, subtitle, image, sizeImg }) => {

  return (
    <div className={styles.wrapper}>
      <img src={image} alt={title} style={{width: sizeImg, height: sizeImg}} />
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
}
