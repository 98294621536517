import React, { useState } from 'react'
import styles from './SwipeToggle.module.css'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'

export const SwipeToggle = ({
	setValue,
	title1,
	title2,
	active = [1, 0],
	type,
}) => {
	const navigate = useNavigate()
	const [selectedOption, setSelectedOption] = useState(active[0])
	const [indicatorPosition, setIndicatorPosition] = useState(active[1])

	const handleOptionChange = (option, index) => {
		setSelectedOption(option)
		setIndicatorPosition(index)
	}

	const chooseOne = () => {
		if (type == 'buttons') {
			navigate('/home')
		} else {
			handleOptionChange(1, 0)
			setValue(true)
		}
	}
	const chooseTwo = () => {
		if (type == 'buttons') {
			navigate('/activity')
		} else {
			handleOptionChange(2, 1)
			setValue(false)
		}
	}

	return (
		<div className={styles.switch_block}>
			<div
				className={styles.switch_indicator}
				style={{ left: indicatorPosition === 1 ? `${indicatorPosition * 49}%` : `5px` }}></div>
			<button
				className={classNames(
					styles.switch_option,
					selectedOption === 1 ? styles.switch_option_active : ''
				)}
				onClick={chooseOne}>
				{title1}
			</button>
			<button
				className={classNames(
					styles.switch_option,
					selectedOption === 2 ? styles.switch_option_active : ''
				)}
				onClick={chooseTwo}>
				{title2}
			</button>
		</div>
	)
}
