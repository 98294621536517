import React from 'react'
import styles from './ItemImage.module.css'
import { Box, Chart } from '../'

export const ItemImage = ({ data }) => {
	return (
		<div className={styles.block}>
			<div className={styles.image}>
				<img src={data.image} />
			</div>
			<div className={styles.col}>
				<div className={styles.title}>{data.title}</div>
				<div className={styles.subtitle}>{data.subtitle}</div>
				<div className={styles.price}>{data.price}</div>
			</div>
		</div>
	)
}
