import React from 'react';
import cn from 'classnames'
import styles from './keyboard.module.css'
import Svg from '../../../svgs/Svg';

export const KeyboardItem = ({setItem, item}) => {
    const [active, setActive] = React.useState(false)
	const [timeoutId, setTimeOutId] = React.useState(null)

	const onPress = () => {
		setActive(true)
		if (item.value >= 0 || item.value === '.') {
			setItem((state) => {
                if(state[0] == 0 && state[1] !== '.' && item.value !== '.') {
                    return state.toString()[0] + '.' + state.slice(1) + item.value
                } else {
                   return state.toString() + item.value
                }
            })
		} else {
			setItem((state) => state !== '' ? state.toString().slice(0, -1) : '')
		}
		setTimeOutId(
			setTimeout(() => {
				setActive(false)
			}, 300)
		)
	}

	React.useEffect(() => {
		return () => {
			clearTimeout(timeoutId)
		}
	}, [])

    return (
        <div className={styles.numBtn} onClick={onPress}>
            <span className={cn(styles.wrapText, {
                [styles.active]: active
            })}>
                {item.value == 'clear' ? (
					<Svg
						type='clear'
						fill={!active ? 'var(--accent)' : 'var(--white)'}
					/>
				) : (
					item.value
				)}
            </span>
        </div>
    )
}