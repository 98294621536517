import React from 'react'
import ContentLoader from 'react-content-loader'

export const ItemLoader = ({ style }) => {
	return (
		<ContentLoader
			style={style}
			speed={2}
			width={350}
			height={68}
			viewBox='0 0 350 68'
			backgroundColor='#17191E'
			foregroundColor='#364052'>
			<rect x='0' y='0' rx='6' ry='6' width='350' height='68' />
		</ContentLoader>
	)
}
