import React from 'react'
import ContentLoader from 'react-content-loader'

export const PriceLoader = (props) => {
    return (
        <ContentLoader
            speed={2}
            width={335}
            height={180}
            viewBox="0 0 335 180"
            backgroundColor='#17191E'
            foregroundColor='#364052'
            {...props}>
            <rect x="0" y="0" rx="16" ry="16" width="335" height="180"/>
        </ContentLoader>

    )
}
