import React from 'react'
import styles from './settings.module.css'
import { ItemBlock, Toggle, Header, Button } from '../../components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const Settings = () => {
	const navigate = useNavigate()
	const { usePin } = useSelector((state) => state.storage)
	const [fingerChecked, setFingerChecked] = React.useState(false)
	const [faceChecked, setFaceChecked] = React.useState(false)
	
	const list = [
		{
			title: 'Use PIN Code',
			icon: 'lock',
			checked: usePin
		},
		{
			title: 'Touch Id',
			icon: 'finger',
			checked: fingerChecked
		},
		{
			title: 'Use Face ID',
			icon: 'face',
			checked: faceChecked
		},
	]

	const handleToggle = (_, title) => {
		switch (title) {
			case 'Use PIN Code':
				if (usePin) {
					navigate('/login-pin', { state: { to: '/settings' } })
				} else {
					navigate('/create-pin', { state: { to: '/settings' } })
				}
				break;
			case 'Touch Id':
				setFingerChecked(!fingerChecked)
				break;
			case 'Use Face ID': 
				setFaceChecked(!faceChecked)
				break;
			default:
				break;
		}
	}

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header to='/settings-account' title='Security' />

				<div className={styles.list} style={{marginTop: 30}}>
					{list.map((item, index) => (
						<ItemBlock style={{paddingTop: 20, paddingBottom: 20}} content={item} key={index}>
							<Toggle
								data={usePin}
								checked={item.checked}
								id={`safety` + index}
								dataFunc={item.title}
								onToggle={handleToggle}
							/>
						</ItemBlock>
					))}
				</div>
			</div>
			{usePin && <div className="page-inner page-align-bottom">
				<Button to='/create-pin' state={{to: '/settings'}}>
					Change my PIN code
				</Button>
			</div>}
		</div>
	)
}
