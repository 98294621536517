import React from 'react';
import styles from './TextRowsBlock.module.css'
import { Box } from '../';
import cn from 'classnames';

const texts = [
	{
		left: 'Transaction Fee',
		right: '0.34 USD',
	},
	{
		left: 'Reception time',
		right: '⚡ Instant (0 to 30 minutes)',
	},
]
const texts2 = [
	{
		left: 'Transaction Fee',
		right: '0.14 USD',
	},
	{
		left: 'Reception time',
		right: '🌱 2 hours in average',
	},
]
export const TextRowsBlock = ({ style }) => {
	const [textRows, setTextRows] = React.useState(texts)
	const [showText, setShowText] = React.useState(true)

	React.useEffect(() => {
		if (showText) {
			setTextRows(texts)
		} else {
			setTextRows(texts2)
		}
	}, [showText])
  return (
    <Box className={styles.block} style={style}>
      <div className={styles.btns}>
        <button onClick={() => setShowText(true)} className={cn(styles.btn, {
          [styles.activeBtn]: showText
        })}>
          Fast
        </button>
        <button onClick={() => setShowText(false)} className={cn(styles.btn, {
          [styles.activeBtn]: !showText
        })}>
          Standart
        </button>
      </div>
      {textRows.map((item, index) => (
        <div className={styles.row} key={index}>
          <div className={styles.topText}>{item.left}</div>
          <div className={styles.bottomText}>{item.right}</div>
        </div>
      ))}
    </Box>
  );
}
