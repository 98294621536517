import React, { useState } from 'react'
import Svg from '../../svgs/Svg'
import styles from './MenuActions.module.css'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const MenuActions = ({ items }) => {
	const navigate = useNavigate()
	const { walletAddress } = useSelector((state) => state.wallet)
	const { usePin } = useSelector((state) => state.storage)
	const [isOpen, setIsOpen] = useState(false)
	const [timeoutID, setTimeoutID] = React.useState(null)
	const [showCopied, setShowCopied] = React.useState(false)

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

	const handleItemClick = (item) => {
		if (item.title === 'Copy Address') {
			navigator.clipboard.writeText(walletAddress)
			setShowCopied(true)
			setTimeoutID(
				setTimeout(() => {
					setShowCopied(false)
					clearTimeout(timeoutID)
				}, 2000)
			)
		}
		if (item.to && item.pass) {
			if (usePin) {
				navigate('/login-pin', { state: { to: item.to } })
			} else {
				navigate(item.to)
			}
		} else if (item.to && !item.pass) {
			navigate(item.to)
		}
	}

	function GenerateItem({ item }) {
		return (
			<li
				className={styles.item}
				style={{ color: item.color }}
				onClick={() => handleItemClick(item)}>
				{item.icon && (
					<span className={styles.icon}>
						<Svg type={item.icon} />
					</span>
				)}

				{showCopied && item.title === 'Copy Address' ? (
					<span>Address has been copied!</span>
				) : (
					<span>{item.title}</span>
				)}

				{!item.icon && (
					<span className={styles.icon_arrow}>
						<Svg type='arrow-right' />
					</span>
				)}
			</li>
		)
	}

	return (
		<div className={styles.container}>
			<div className={styles.button} onClick={handleClick}>
				{!isOpen ? (
					<Svg type='ellipsis' />
				) : (
					<>
						<Svg type='close' style={{ width: '13px', height: '13px' }} />
						<div className={styles.backdrop}></div>
					</>
				)}
			</div>
			<ul
				className={classNames(styles.items, isOpen ? styles.items_active : '')}>
				{items.map((item, index) => {
					if (usePin && item.title == 'Lock') {
						return <GenerateItem item={item} key={index} />
					} else if (!usePin && item.title == 'Lock') {
						return null
					} else {
						return <GenerateItem item={item} key={index} />
					}
				})}
			</ul>
		</div>
	)
}
