import React from 'react'
import styles from './send.module.css'
import {
	Button,
	Menu,
	Header,
	Keyboard
} from '../../components'
import {
	setChooseCoinOne,
	setChooseCoinTwo,
	setAmountSend,
} from '../../redux/slices/TransactionsSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import fixNum from '../Func.wallet/fixNum'

export const SendAmount = () => {
	const { state } = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { chooseCoinOne, amountSend } = useSelector(
		(state) => state.transaction
	)
	const [disabledBtn, setDisabledBtn] = React.useState(true)
	const [amountBuy, setAmountBuy] = React.useState('')
	React.useEffect(() => {
		if(amountSend!== '') {
			setAmountBuy(amountSend)
		}
	}, [])

	React.useEffect(() => {
		if (
			amountBuy !== '' &&
			amountBuy > 0 &&
			chooseCoinOne.market_data.balance >= amountBuy
		) {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [amountBuy])

	React.useEffect(() => {
		if (state !== null) {
			if (state.from == 'swapOne') {
				dispatch(setChooseCoinOne(state.item))
			}
			if (state.from == 'swapTwo') {
				dispatch(setChooseCoinTwo(state.item))
			}
		}
	}, [state])

	const setValue = () => {
		dispatch(setAmountSend(amountBuy))
		navigate('/send-address')
	}


	const onMax = () => {
		setAmountBuy(chooseCoinOne.market_data.balance)
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Send a crypto' />

				{chooseCoinOne !== null ? (
					<div className={styles.wrapCoin}>
						<button onClick={onMax} className={styles.maxBtn}>MAX</button>
						<span className={styles.coinPrice}>${amountBuy !== '' ? amountBuy : '0'}</span>
						<div className={styles.row} style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
							<h5 className={styles.coinTitle}><span>{fixNum(chooseCoinOne.market_data.balance)}</span> {chooseCoinOne.symbol}</h5>
						</div>
					</div>
				) : (
					<ItemLoader style={{ marginTop: 20 }} />
				)}
				<div className={styles.chooseCoin}>
					<div className={styles.chooseCoinInfo} onClick={() => navigate('/swap-coins', {state: {to: '/send-amount'}})}>
						<img src={chooseCoinOne.image.thumb} alt={chooseCoinOne.symbol} />
						<p className={styles.chooseTitle}>{chooseCoinOne?.name || chooseCoinOne?.symbol }</p>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M3.0624 5.93942L7.43052 11.0369C7.50093 11.119 7.58827 11.185 7.68655 11.2302C7.78483 11.2753 7.89173 11.2987 7.9999 11.2987C8.10807 11.2987 8.21497 11.2753 8.31325 11.2302C8.41153 11.185 8.49887 11.119 8.56927 11.0369L12.9374 5.93942C13.3543 5.45286 13.0086 4.70129 12.368 4.70129H3.63053C2.9899 4.70129 2.64428 5.45286 3.0624 5.93942Z" fill="white"/>
						</svg>
					</div>
					<span className={styles.chooseCoinPrice}>
						$ {fixNum(chooseCoinOne.market_data.balance_crypto)}
					</span>
				</div>
				<Keyboard style={{marginTop: 40}} setValue={setAmountBuy} value={amountBuy} />
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					onClick={setValue}
					variant={disabledBtn ? 'disabled' : 'default'}>
					Continue
				</Button>
			</div>
		</div>
	)
}
