import React from 'react';
import cn from 'classnames'
import styles from './cardPrice.module.css'
import { Indicators } from '../Indicators/Indicators';
import { useNavigate } from 'react-router-dom';

export const CardPrice = ({balance, change}) => {
    const navigate = useNavigate()
    return (
        <div className={styles.card}>
            {change !== null ? (
                <Indicators
                    style={{position: 'absolute', top: 16, right: 16}}
                    usd={change.usd}
                    percent={change.percent}
                />
            ) : (
                <></>
            )}
            <span className={styles.priceTitle}>${balance}</span>
            <div className={styles.buttons}>
                <button onClick={() => navigate('/swap-coins', {
								state: { to: '/send-amount', from: 'swapOne' },
							})}>
                    Send
                </button>
                <button onClick={() => navigate('/receive')}>
                    Receive
                </button>
            </div>
        </div>
    )
}