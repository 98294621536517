import React from 'react'
import styles from './receive.module.css'
import { Menu, Header } from '../../components'
import Svg from '../../svgs/Svg'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

let timerID

export const Receive = () => {
	const { walletAddress } = useSelector((state) => state.wallet)
	const [copied, setCopied] = React.useState(false)
	const [showShareBody, setShowShareBody] = React.useState(false)

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(walletAddress)
			setCopied(true)
			timerID = setTimeout(() => {
				setCopied(false)
				clearTimeout(timerID)
			}, 1000);
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Receive' />
				<div className={styles.qr}>
					<QRCode
						bgColor='var(--primary)'
						fgColor='var(--white)'
						size={285}
						style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
						value={walletAddress}
					/>
				</div>
				<div>
					<h4 className={styles.titleAddress}>My Tokenname address</h4>
					<p className={styles.par}>{walletAddress}</p>
				</div>
				<div className={cn('btns', styles.wrapBtn)}>
					<button className={styles.buttonCopy} onClick={handleCopyToClipboard}>
						{copied ? <Svg type='check' fill='var(--green)'/> : <Svg type='copy-filled' fill='var(--white)'/>}
						{copied ? <span style={{color: 'var(--green)'}}>Address copied</span> : <span>Copy</span>}
					</button>
					<button onClick={() => setShowShareBody(!showShareBody)} className={styles.buttonCopy} style={{position: 'relative'}}>
						<Svg type='share' fill='var(--white)'/>
						<span>Share</span>
						<div className={cn(styles.bodyShared, {
							[styles.showShareBody]: showShareBody
						})}>
							<TelegramShareButton url={walletAddress}>
								<TelegramIcon />
							</TelegramShareButton>
							<FacebookShareButton url={walletAddress}>
								<FacebookIcon />
							</FacebookShareButton>
							<EmailShareButton>
								<EmailIcon/>
							</EmailShareButton>
							<TwitterShareButton>
								<TwitterIcon/>
							</TwitterShareButton>
						</div>
					</button>
				</div>
				
			</div>
		</div>
	)
}
