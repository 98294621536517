import React from 'react'
import Svg from '../../svgs/Svg'
import styles from './Menu.module.css'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setMenuChoose } from '../../redux/slices/SettingsSlice'

export const Menu = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { menuChoose } = useSelector((state) => state.settings)

	const handleClick = (index) => {
		dispatch(setMenuChoose(index))
		switch (index) {
			case 1:
				navigate('/buy')
				break
			case 2:
				navigate('/swap')
				break
			case 3:
				navigate('/settings-account')
				break
			default:
				navigate('/home')
		}
	}

	const menuLinks = [
		{
			icon: 'menu-item-1',
			iconActive: 'menu-item-1-active',
		},
		{
			icon: 'menu-item-2',
			iconActive: 'menu-item-2-active',
		},
		{
			icon: 'menu-item-3',
			iconActive: 'menu-item-3-active',
		},
		{
			icon: 'menu-item-4',
			iconActive: 'menu-item-4-active',
		},
	]

	return (
		<nav className={styles.row}>
			{menuLinks.map((item, index) => (
				<div
					className={classNames(
						styles.item,
					)}
					onClick={() => handleClick(index)}
					key={index}>
					<Svg type={menuChoose === index ? item.iconActive : item.icon} />
					{menuChoose === index && <Svg type='dot' style={{width: 6, height: 6, marginTop: 8}} />}
				</div>
			))}
		</nav>
	)
}
