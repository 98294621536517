import React from 'react'
import styles from './generate.module.css'
import { Button, Digits } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setPasswordInit } from '../../redux/slices/WalletSlice'
import { setUsePin } from '../../redux/slices/StorageSlice'

export const LoginPin = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { password, usePin } = useSelector((state) => state.storage)
	const { passwordInit } = useSelector((state) => state.wallet)
	const [validInp, setValidInp] = React.useState(false)
	const [disabledBtnPass, setDisabledBtnPass] = React.useState(true)
	const [clearInputs, setClearInputs] = React.useState(false)

	React.useEffect(() => {
		if (passwordInit !== '' && password !== '') {
			if (
				passwordInit === password &&
				passwordInit.length === 6 &&
				password.length === 6
			) {
				setValidInp(true)
			} else {
				setValidInp(false)
			}

			if (passwordInit.length === 6 && password.length === 6) {
				setDisabledBtnPass(false)
			} else {
				setDisabledBtnPass(true)
			}
		}
	}, [passwordInit, password])

	const createPass = () => {
		if (validInp) {
			dispatch(setPasswordInit(''))
			if (state.to == '/settings') {
				dispatch(setUsePin(!usePin))
			}
			if (state.to == '/wallets') {
				navigate(state.to, { state: 'openModal' })
			} else {
				navigate(state.to)
			}
		} else {
			dispatch(setPasswordInit(''))
			setClearInputs(true)
			const timerID = setTimeout(() => {
				setClearInputs(false)
				clearTimeout(timerID)
			}, 100)
		}
	}

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className={styles.title}>Confirm your PIN code</div>
				<div className={styles.subtitle}>Enter a PIN code to continue.</div>
				<Digits name='login' clear={clearInputs} />
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtnPass ? 'disabled' : 'default'}
					onClick={createPass}>
					Continue
				</Button>
				<Button variant='inline' to={-1}>
					Cancel
				</Button>
			</div>
		</div>
	)
}
