import React from 'react'
import styles from './welcome.module.css'
import { useNavigate } from 'react-router-dom'

import logo from '../../assets/images/logo.svg'

export const Start = () => {
	const navigate = useNavigate()

	return (
		<div className='page'>
			<div className="page-inner" style={{paddingTop: 70}}>
				<div className={styles.logo}>
					<img src={logo} alt='Ordinals Wallet' />
					<div className={styles.title}>
						Let's get you <br/> started!
					</div>
				</div>
				<div className={styles.wrapBtns}>
					<button className={styles.buttonBig} onClick={() => navigate('/generate')}>
						<span className={styles.buttonTitle}>
							Get a new wallet
						</span>
						<p className={styles.buttonPar}>
							I am new to crypto, or I want a shiny new wallet!
						</p>
						<span className={styles.arrow}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.3915 12.29L4.82264 12.3077C4.5398 12.3077 4.30103 12.2103 4.10634 12.0156C3.91212 11.8214 3.81501 11.5829 3.81501 11.3C3.81501 11.0172 3.91236 10.7784 4.10705 10.5837C4.30127 10.3895 4.5398 10.2924 4.82264 10.2924L17.3915 10.3101L12.0175 4.93609C11.8171 4.73574 11.7167 4.50004 11.7162 4.22898C11.7167 3.95792 11.8171 3.72222 12.0175 3.52187C12.2178 3.32153 12.4535 3.22112 12.7246 3.22065C12.9956 3.22112 13.2313 3.32153 13.4317 3.52187L20.5027 10.5929C20.7031 10.7933 20.8033 11.0288 20.8033 11.2993C20.8033 11.5709 20.7031 11.8068 20.5027 12.0072L13.4317 19.0782C13.2313 19.2786 12.9956 19.3785 12.7246 19.378C12.4535 19.3785 12.2178 19.2786 12.0175 19.0782C11.8171 18.8779 11.7172 18.6422 11.7176 18.3711C11.7172 18.1001 11.8171 17.8644 12.0175 17.664L17.3915 12.29Z" fill="white"/>
							</svg>
						</span>
					</button>
					<button className={styles.buttonBig} onClick={() => navigate('/import')}>
						<span className={styles.buttonTitle}>
							Import an existing wallet
						</span>
						<p className={styles.buttonPar}>
							I already have a wallet and I want to<br/>access my cryptos in Ordinals Wallet
						</p>
						<span className={styles.arrow}>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.3915 12.29L4.82264 12.3077C4.5398 12.3077 4.30103 12.2103 4.10634 12.0156C3.91212 11.8214 3.81501 11.5829 3.81501 11.3C3.81501 11.0172 3.91236 10.7784 4.10705 10.5837C4.30127 10.3895 4.5398 10.2924 4.82264 10.2924L17.3915 10.3101L12.0175 4.93609C11.8171 4.73574 11.7167 4.50004 11.7162 4.22898C11.7167 3.95792 11.8171 3.72222 12.0175 3.52187C12.2178 3.32153 12.4535 3.22112 12.7246 3.22065C12.9956 3.22112 13.2313 3.32153 13.4317 3.52187L20.5027 10.5929C20.7031 10.7933 20.8033 11.0288 20.8033 11.2993C20.8033 11.5709 20.7031 11.8068 20.5027 12.0072L13.4317 19.0782C13.2313 19.2786 12.9956 19.3785 12.7246 19.378C12.4535 19.3785 12.2178 19.2786 12.0175 19.0782C11.8171 18.8779 11.7172 18.6422 11.7176 18.3711C11.7172 18.1001 11.8171 17.8644 12.0175 17.664L17.3915 12.29Z" fill="white"/>
							</svg>
						</span>
					</button>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<p className={styles.footerPar}>By proceeding, you agree to <br /> Ordinals Wallet <a href="https://robinhood.com/us/en/support/articles/privacy-policy/" target='_blank'>Terms of Use</a></p>
			</div>
		</div>
	)
}
