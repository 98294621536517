import React from 'react'
import styles from './send.module.css'
import { Menu } from '../../components'
import Svg from '../../svgs/Svg'
import { Header } from '../../components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import fixNum from '../Func.wallet/fixNum'
import { ItemLoader } from '../../components/Loaders/ItemLoader'
import { setChooseCoinOne } from '../../redux/slices/TransactionsSlice'

export const SwapCoins = () => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const navigate = useNavigate()
	const { allCoins } = useSelector((state) => state.wallet)
	const [searchValue, setSearchValue] = React.useState('')
	const [filtered, setFiltered] = React.useState([])

	React.useEffect(() => {
		if (allCoins !== null) {
			if (searchValue !== '') {
				setFiltered(
					allCoins.filter(
						(item) =>
							item.symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
							item.name.toLowerCase().includes(searchValue.toLowerCase())
					)
				)
			} else {
				setFiltered(allCoins)
			}
		}
	}, [searchValue, allCoins])

	const onChooseCoin = (item) => {
		dispatch(setChooseCoinOne(item))
		navigate(state.to, { state: { item, from: state.from } })
	}

	function title() {
		switch (state.to) {
			case '/swap':
				return 'Swap'
			case '/buy':
				return 'Buy'
			default:
				return 'Send'
		}
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner'>
				<Header title={title() + ' a crypto'} />
				<div className='subtitle'>
					Choose what crypto you want to {title().toLowerCase()}.
				</div>
				<div className={styles.search}>
					<Svg type='search' />
					<input
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
						type='text'
						placeholder='Search Crypto'
						className={styles.search_input}
					/>
				</div>
				<div className={styles.list}>
					{allCoins.length ? (
						filtered.length ? (
							filtered.map((item, index) => (
								<div
									className={styles.item}
									key={index}
									style={{ cursor: 'pointer' }}
									onClick={() => onChooseCoin(item)}>
									<div className={styles.item_img}>
										<img src={item.image.thumb} alt={item.name} />
									</div>
									<div className={styles.item_content}>
										<div className={styles.item_title}>{item.name}</div>
										<span className={styles.item_subtitle}>
											{fixNum(item.market_data.balance)}{' '}
											{item.symbol.toUpperCase()}
										</span>
									</div>
									<div className={styles.item_price}>
										${fixNum(item.market_data.balance_crypto)}
									</div>
								</div>
							))
						) : (
							<></>
						)
					) : (
						<div className='items-loader'>
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
