import React from 'react'
import styles from './send.module.css'
import { Menu, Toggle } from '../../components'
import Svg from '../../svgs/Svg'
import { Header } from '../../components'
import { useSelector } from 'react-redux'
import fixNum from '../Func.wallet/fixNum'
import { useDispatch } from 'react-redux'
import { setChooseAssets } from '../../redux/slices/StorageSlice'
import { ItemLoader } from '../../components/Loaders/ItemLoader'

export const MainManage = () => {
	const dispatch = useDispatch()
	const { allCoins } = useSelector((state) => state.wallet)
	const { chooseAssets } = useSelector((state) => state.storage)
	const [filtered, setFiltered] = React.useState([])
	const [value, setValue] = React.useState('')

	React.useEffect(() => {
		if (allCoins !== null) {
			if (value !== '') {
				const filteredCoins = allCoins.filter(
					(item) =>
						item.name.toLowerCase().includes(value.toLowerCase()) ||
						item.symbol.toLowerCase().includes(value.toLowerCase())
				)
				setFiltered(filteredCoins)
			} else {
				setFiltered(allCoins)
			}
		}
	}, [allCoins, value])

	const handleToggle = (item) => {
		dispatch(setChooseAssets(item))
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Manage cryptos' />

				<div className='subtitle'>
					Choose what cryptos will be in your wallet.
				</div>
				<div className={styles.search}>
					<Svg type='search' />
					<input
						value={value}
						onChange={(e) => setValue(e.target.value)}
						type='text'
						placeholder='Search Crypto'
						className={styles.search_input}
					/>
				</div>
				<div className={styles.list}>
					{allCoins.length ? (
						filtered.length ? (
							filtered.map((item, index) => (
								<div className={styles.item} key={index}>
									<div className={styles.item_img}>
										<img src={item.image.thumb} alt={item.symbol} />
									</div>
									<div className={styles.item_content}>
										<div className={styles.item_title}>{item.name}</div>
										<span className={styles.item_subtitle}>
											{fixNum(item.market_data.balance)}
											{' ' + item.symbol.toUpperCase()}
										</span>
									</div>
									<Toggle
										checked={chooseAssets.includes(item.symbol.toLowerCase())}
										id={`toggle` + index}
										data={item.symbol}
										onToggle={handleToggle}
									/>
								</div>
							))
						) : (
							<></>
						)
					) : (
						<div className='items-loader'>
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
							<ItemLoader />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
