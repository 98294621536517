import React from 'react'
import styles from './generate.module.css'
import { Button, Digits } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	setPassword,
	setIsLogin,
	setUsePin,
} from '../../redux/slices/StorageSlice'

export const ConfirmPin = () => {
	const { state } = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { passwordInit, passwordConfirm } = useSelector((state) => state.wallet)
	const [validInp, setValidInp] = React.useState(false)
	const [disabledBtnPass, setDisabledBtnPass] = React.useState(true)

	React.useEffect(() => {
		if (passwordInit !== '' && passwordConfirm !== '') {
			if (
				passwordInit === passwordConfirm &&
				passwordInit.length === 6 &&
				passwordConfirm.length === 6
			) {
				setValidInp(true)
			} else {
				setValidInp(false)
			}

			if (passwordInit.length === 6 && passwordConfirm.length === 6) {
				setDisabledBtnPass(false)
			} else {
				setDisabledBtnPass(true)
			}
		}
	}, [passwordInit, passwordConfirm])

	const createPass = () => {
		if (validInp) {
			dispatch(setPassword(passwordInit))
			if (state !== null && state.to == '/settings') {
				dispatch(setUsePin(true))
				navigate(state.to)
			} else {
				dispatch(setUsePin(true))
				navigate('/home')
			}
		} else {
			navigate('/create-pin')
		}
		dispatch(setIsLogin(true))
	}

	return (
		<div className='page'>
			<div className='page-inner page-full-height'>
				<div className={styles.title}>Confirm your PIN code</div>
				<div className={styles.subtitle}>Enter a PIN code to continue.</div>
				<Digits name='confirm' />
			</div>
			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtnPass ? 'disabled' : 'default'}
					onClick={createPass}>
					Continue
				</Button>
				<Button variant='inline' to={-1}>
					Cancel
				</Button>
			</div>
		</div>
	)
}
