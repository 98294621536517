import React from 'react'
import styles from './send.module.css'
import { Menu } from '../../components'
import Svg from '../../svgs/Svg'
import { Header } from '../../components'
import logo1 from '../../assets/images/coin-6.png'
import logo2 from '../../assets/images/coin-5.png'

export const Send = () => {
	const list = [
		{
			logo: logo1,
			title: 'Stacks',
			amount: '0 STX',
			price: '$340.16',
		},
		{
			logo: logo2,
			title: 'Stacks',
			amount: '0 STX',
			price: '$32.42',
		},
	]

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Send a crypto' />

				<div className='subtitle'>Choose what crypto you want to send.</div>
				<div className={styles.search}>
					<Svg type='search' />
					<input
						type='text'
						placeholder='Search Crypto'
						className={styles.search_input}
					/>
				</div>
				<div className={styles.list}>
					{list.map((item, index) => (
						<div className={styles.item} key={index}>
							<div className={styles.item_img}>
								<img src={item.logo} alt={item.title} />
							</div>
							<div className={styles.item_content}>
								<div className={styles.item_title}>{item.title}</div>
								<span className={styles.item_subtitle}>{item.amount}</span>
							</div>
							<div className={styles.item_price}>{item.price}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
