import React, { useState, useEffect } from 'react'
import styles from './FormColumns.module.css'
import { Input, Box } from '../'

import classNames from 'classnames/bind'

export const FormColumns = ({
	values,
	onChangeInput,
	inputsList,
	inputsListOnce,
	randomValues,
	setNums,
	check = false
}) => {
	const [randomNumbers, setRandomNumbers] = useState([])
	
	React.useEffect(() => {
		if (setNums) setNums(randomNumbers)
	}, [randomNumbers])

	useEffect(() => {
		const generateRandomNumbers = () => {
			const uniqueNumbers = new Set()

			while (uniqueNumbers.size < inputsList.length) {
				uniqueNumbers.add(Math.floor(Math.random() * 12) + 1)
			}

			setRandomNumbers(Array.from(uniqueNumbers))
		}

		generateRandomNumbers()
	}, [])

	if (inputsList) {
		var halfList = Math.floor(inputsList.length / 2)
		var firstColumn = inputsList.slice(0, halfList)
		var secondColumn = inputsList.slice(halfList)
	}

	return (
		<Box style={{padding: 0}}>
			{randomValues ? (
				<div className={styles.inner_row}>
					<div className={classNames(styles.inner_col, styles.inner_col_full)}>
						{inputsList.map((item, i) => (
							<div className={classNames(styles.item, {
								[styles.doubleItem]: true
							})} key={'0' + i}>
								<span className={styles.item_index}>{randomNumbers[i]}</span>
								<Input
									value={values[i]}
									handleChange={onChangeInput}
									placeholder='Word here'
									indx={i}
								/>
							</div>
						))}
					</div>
				</div>
			) : inputsList ? (
				<div className={styles.inner_row}>
					<div className={styles.inner_col}>
						{firstColumn.map((item, i) => (
							<div className={styles.item} key={'0' + i}>
								<span className={styles.item_index}>{i + 1}.</span>
								<Input placeholder={item} className={styles.input} />
							</div>
						))}
					</div>
					<div className={styles.inner_col}>
						{secondColumn.map((item, i) => (
							<div className={styles.item} key={i}>
								<span className={styles.item_index}>{i + halfList + 1}.</span>
								<Input placeholder={item} className={styles.input} />
							</div>
						))}
					</div>
				</div>
			) : (
				<div className={styles.inner_row_once}>
					<div className={styles.inner_col}>
						{inputsListOnce.map((item, i) => (
							<div key={i}>
								<div className={styles.label}>
									Enter a recovery word {i + 1}
								</div>
								<div className={styles.item} key={i}>
									<span className={styles.item_index}>{i + 1}.</span>
									<Input placeholder={item} className={styles.input} />
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</Box>
	)
}
