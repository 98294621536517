import React from 'react'
import styles from './import.module.css'
import { ButtonLink, Header } from '../../components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import coin1 from '../../assets/images/coin-1.png'
import coin2 from '../../assets/images/coin-2.png'
import coin3 from '../../assets/images/coin-3.png'
import coin4 from '../../assets/images/coin-4.png'
import coin5 from '../../assets/images/coin-5.png'

export const ImportStart = () => {
	const navigate = useNavigate()
	const { backup } = useSelector((state) => state.storage)

	return (
		<div className='page'>
			<div className='page-inner'>
				<Header />
				<div className={styles.titleImport}>Follow these steps to <br/>import your wallet</div>

				<div className={styles.list}>
					<ButtonLink
						onClick={() => navigate('/backup')}
						title='Create a backup for you current Ordinals Wallet'
						subtitle='This step is required, even if your current Name wallet is empty, for security.'
					/>
					<ButtonLink
						disabled={!backup}
						onClick={() => navigate('/import')}
						title='Import your other wallet using a recovery phrase'
						subtitle={<div className={styles.imgWrap}>
							<img className={styles.imgCoin} src={coin1}/>
							<img className={styles.imgCoin} src={coin2}/>
							<img className={styles.imgCoin} src={coin3}/>
							<img className={styles.imgCoin} src={coin4}/>
							<img className={styles.imgCoin} src={coin5}/>
						</div>}
					/>
				</div>
			</div>
		</div>
	)
}
