import React, { useState } from 'react'
import styles from './PriceInput.module.css'
import { Box, TheSelect } from '../../components'
import classNames from 'classnames/bind'
import Svg from './../../svgs/Svg'
import { useNavigate } from 'react-router-dom'
import fixNum from '../../pages/Func.wallet/fixNum'

export const PriceInput = ({
	value,
	setValue,
	title,
	chooseItem,
	from,
	disabled,
	to,
}) => {
	const [isFocused, setIsFocused] = useState(false)
	const [update, setUpdate] = React.useState({})
	const navigate = useNavigate()
	const handleFocus = () => {
		setIsFocused(true)
	}

	const handleBlur = () => {
		setIsFocused(false)
	}

	const onMax = () => {
		setValue(chooseItem.market_data.balance)
		setUpdate({})
	} 
	
	return (
		<Box
			className={classNames(styles.box, isFocused ? styles.box_focused : '')}>
			<div className={styles.left}>
				<h3 style={{marginBottom: 10}} className={styles.titleGray}>You sell</h3>
				{chooseItem !== null && (
					<div
						className='choose-item'
						onClick={() => navigate('/swap-coins', { state: { from, to: to } })}>
						<img src={chooseItem.image.thumb} alt={chooseItem.name} />
						<span>{chooseItem.symbol.toUpperCase()}</span>
						<Svg type='triangle' />
					</div>
				)}
				<h3 style={{marginTop: 10}} className={styles.titleGray}>
					{chooseItem.name}
				</h3>
			</div>
			<div className={styles.right}>
				<div className={styles.line}>
					<span style={{color: 'var(--gray)'}}>Balance:</span>
					<span className={styles.subLine}>
						{fixNum(chooseItem.market_data.balance) + ' '}
					</span>
					{!disabled && <span className={styles.max} onClick={onMax}>
						Max
					</span>}
				</div>
				<div
					className={styles.row}
					style={disabled ? { pointerEvents: 'none' } : {}}>
					<div className={styles.inputWrap}>
						{!disabled && update && <input
							style={value.toString().length > 2 ? {width: value.toString().length + "ch"} : {width: 45}}
							onInput={(e) => setValue(e.target.value)}
							value={value}
							type='text'
							className={styles.input}
							placeholder='0.0'
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>}
					</div>
				</div>
			</div>
		</Box>
	)
}
