import React from 'react'
import styles from './home.module.css'
import {
	Menu,
	Indicators,
	Button,
	Transaction,
	ButtonActions,
} from '../../components'
import Svg from '../../svgs/Svg'

import coin1 from '../../assets/images/coin-1.png'
import classNames from 'classnames/bind'
import { Area, AreaChart } from 'recharts'
import { useLocation } from 'react-router-dom'
import fixNum from '../Func.wallet/fixNum'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	fetchChartCoin,
	fetchChartCoinDefault,
} from '../../redux/slices/WalletSlice'
import { ChartLoader } from './../../components/Loaders/ChartLoader'

export const AssetInfo = () => {
	const { state } = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [activeTimeline, setActiveTimeline] = React.useState('All')
	const { chartBitcoin, statusChartBitcoin } = useSelector(
		(state) => state.wallet
	)

	const buttonActions = [
		{
			title: '1D',
		},
		{
			title: '7D',
		},
		{
			title: '1M',
		},
		{
			title: '1Y',
		},
		{
			title: 'All',
		},
	]

	React.useEffect(() => {
		if (state !== null && state.contract_address !== '') {
			dispatch(fetchChartCoin([state.contract_address, setPeriod()]))
		} else {
			dispatch(fetchChartCoinDefault([state.id, setPeriod(true)]))
		}
	}, [activeTimeline])

	const setPeriod = (eth) => {
		const day = eth ? '1' : 'day'
		const week = eth ? '7' : 'week'
		const month = eth ? '30' : 'month'
		const year = eth ? '360' : 'day'
		const all = eth ? 'max' : 'max'
		switch (activeTimeline) {
			case '1D':
				return day
			case '7D':
				return week
			case '1M':
				return month
			case '1Y':
				return year
			case 'All':
				return all
			default:
				break
		}
	}

	return (
		<div className='page'>
			{/* Меню наверху, чтобы влиять на стили page-inner */}
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<div className='page-header'>
					<div className={styles.title}>
						<div className={styles.title_logo}>
							<img src={state !== null ? state.image.thumb : ''} />
						</div>
						{state !== null ? state.name : ''}
					</div>
					<div
						className={styles.link}
						onClick={() => navigate('/buy', { state: { item: state } })}>
						Buy
						<Svg type='menu-item-2' />
					</div>
				</div>

				

				<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%', marginBottom: 10, marginTop: 34}}>
					<div className={styles.info}>
						<div className={styles.heading}>Price</div>
						<div className={classNames('title', styles.title_info)}>
							$ {state !== null ? fixNum(state.market_data.current_price) : ''}
							<div className={styles.notification}>
								<Svg type='bell' />
							</div>
						</div>
					</div>
					<div className={styles.actions}>
						<button
							onClick={() =>
								navigate('/send-amount', {
									state: { item: state, from: 'swapOne' },
								})
							}>
							Send
						</button>
						<button
							onClick={() => navigate('/receive', { state: { item: state } })}>
							Receive
						</button>
					</div>
				</div>
				<Indicators
					style={{marginBottom: 24}}
					percent={state.changes.percent}
					usd={state.changes.absolute}
				/>

				<div className={styles.wrapBlock}>
					<div className={styles.chart}>
						{chartBitcoin !== null ? (
							<AreaChart
								width={350}
								height={130}
								data={chartBitcoin}
								margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
								<defs>
									<linearGradient id='value' x1='0' y1='0' x2='0' y2='1'>
										<stop offset='5%' stopColor='#AD94F8' stopOpacity={0.8} />
										<stop offset='95%' stopColor='#AD94F8' stopOpacity={0} />
									</linearGradient>
								</defs>
								<Area
									type='monotone'
									dataKey='value'
									stroke='#AD94F8'
									fillOpacity={1}
									fill='url(#value)'
								/>
							</AreaChart>
						) : (
							<ChartLoader />
						)}
					</div>
					<ButtonActions
						disabled={statusChartBitcoin !== 'success'}
						activeItem={4}
						type='timeline'
						onClick={setActiveTimeline}
						actions={buttonActions}
					/>
				</div>

				<div className={styles.list}>
					<div className='list-title'>Available Balance</div>

					{state !== null ? <Transaction data={state} /> : <></>}
				</div>
			</div>
		</div>
	)
}
