import React from 'react'
import styles from './send.module.css'
import {
	Button,
	Menu,
	SwipeToggle,
	TextRowsBlock,
	Popup,
} from '../../components'
import { Header } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import {
	setAddressTo,
	setAmountSend,
} from '../../redux/slices/TransactionsSlice'
import { useNavigate } from 'react-router-dom'
import transactionsSend from '../Func.wallet/transaction'
import { setDataWallet } from '../../redux/slices/WalletSlice'
import CryptoJS from 'crypto-js'
import fixNum from '../Func.wallet/fixNum'
import Svg from '../../svgs/Svg'
const kitkat = 'Qsx@ah&OR82WX9T6gCt'

const texts = [
	{
		left: 'Transaction Fee',
		right: '0.34 USD',
	},
	{
		left: 'Reception time',
		right: '⚡ Instant (0 to 30 minutes)',
	},
]
const texts2 = [
	{
		left: 'Transaction Fee',
		right: '0.14 USD',
	},
	{
		left: 'Reception time',
		right: '🌱 2 hours in average',
	},
]
export const SendPreview = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { dataUser, currentWallet } = useSelector((state) => state.storage)
	const { chooseCoinOne, addressTo, amountSend } = useSelector(
		(state) => state.transaction
	)
	const { walletAddress } = useSelector((state) => state.wallet)
	const [textRows, setTextRows] = React.useState(texts)
	const [disabledBtn, setDisabledBtn] = React.useState(true)
	const [showText, setShowText] = React.useState(true)
	const [checkEther, setCheckEther] = React.useState(false)
	const [hash, setHash] = React.useState('')
	const [openModalSuccess, setOpenModalSuccess] = React.useState(false)
	const [openModalGas, setOpenModalGas] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [currentAccount, setCurrentAccount] = React.useState(null)

	React.useEffect(() => {
		setCurrentAccount(dataUser.find((item) => item.name == currentWallet))
	}, [dataUser, currentWallet])

	const buttonActions = [
		{
			title: '20%',
		},
		{
			title: '50%',
		},
		{
			title: '75%',
		},
		{
			title: '100%',
		},
	]

	const goHome = () => {
		dispatch(setDataWallet(null))
		navigate('/home')
	}

	React.useEffect(() => {
		if (chooseCoinOne != null && chooseCoinOne.symbol.toLowerCase() == 'eth') {
			setCheckEther(true)
		} else {
			setCheckEther(false)
		}
	}, [chooseCoinOne])

	function onOpenModalSuccess(value) {
		setOpenModalSuccess(value)
		setLoading(false)
	}
	function onOpenModalGas(value) {
		setOpenModalGas(value)
		setLoading(false)
	}

	const onSentToken = () => {
		if (!loading) {
			if (
				amountSend != '' &&
				amountSend > 0 &&
				addressTo != '' &&
				chooseCoinOne != null
			) {
				setLoading(true)
				transactionsSend(
					walletAddress,
					addressTo,
					chooseCoinOne.contract_address,
					amountSend,
					checkEther,
					setHash,
					onOpenModalSuccess,
					onOpenModalGas,
					CryptoJS.AES.decrypt(currentAccount.privateKey, kitkat).toString(
						CryptoJS.enc.Utf8
					)
				)
			}
		}
	}

	React.useEffect(() => {
		if (amountSend !== '' && amountSend > 0 && addressTo !== '') {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [amountSend, addressTo])

	React.useEffect(() => {
		if (showText) {
			setTextRows(texts)
		} else {
			setTextRows(texts2)
		}
	}, [showText])

	const setValue = (value) => {
		dispatch(setAddressTo(value))
	}

	const setAmount = (value) => {
		dispatch(setAmountSend(value))
	}

	const onChoosePercent = (value) => {
		if (chooseCoinOne != null) {
			dispatch(
				setAmountSend(
					((chooseCoinOne.market_data.balance / 100) * Number(value)).toString()
				)
			)
		}
	}

	return (
		<div className='page'>
			<div className='page-menu'>
				<Menu />
			</div>

			<div className='page-inner'>
				<Header title='Preview Details' />
				<div className={styles.wrapCoin}>
					<span className={styles.coinPrice}>${amountSend !== '' ? amountSend : '0'}</span>
					<div className={styles.row} style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
						<h5 className={styles.coinTitle}><span>{fixNum(chooseCoinOne.market_data.balance)}</span> {chooseCoinOne.symbol}</h5>
					</div>
				</div>
				<div className={styles.wrapAddress} style={{pointerEvents: 'none'}}>
					<input placeholder='Paste address' className={styles.inputAddress} type="text" value={addressTo.slice(0, 30) + '...' + addressTo.slice(-7)}  />
				</div>
				<TextRowsBlock style={{marginTop: 30}} content={textRows} />
				<div className={styles.footer}>
					Ordinals Wallet cannot recover any lost funds.
				</div>
			</div>

			<div className='page-inner page-align-bottom'>
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					onClick={onSentToken}>
					Confirm transaction
				</Button>
			</div>
			<Popup
				isOpen={openModalGas}
				title='Attention'
				label='Attention modal'
				text='Not enough funds'>
				<Button variant='default' onClick={() => setOpenModalGas(false)}>
					Ok
				</Button>
			</Popup>
			<Popup
				isOpen={openModalSuccess}
				title='Success'
				label='Success modal'
				text='Your transaction has been sent.'>
				<Button variant='default' onClick={goHome}>
					Ok
				</Button>
			</Popup>
		</div>
	)
}
