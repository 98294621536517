import React from 'react'
import styles from './backup.module.css'
import { Button, Header, Menu } from '../../components'

import welcome from '../../assets/images/welcome.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Backup = () => {
	const { usePin } = useSelector((state) => state.storage)
	const navigate = useNavigate()

	const goBackUp = () => {
		if (usePin) {
			navigate('/login-pin', { state: { to: '/backup-start' } })
		} else {
			navigate('/backup-start')
		}
	}

	return (
		<div className='page'>
			<div style={{padding: 20}}>
				<Header title='Manual backup'/>
			</div>
			<div className='page-menu'>
				<Menu />
			</div>
			<div className='page-inner' style={{display: 'flex', alignItems: 'center'}}>
				<Button variant='default' onClick={goBackUp}>
					Perform manual backup
				</Button>
			</div>
		</div>
	)
}
