import React, { useState } from 'react'
import Svg from '../../../svgs/Svg'
import classNames from 'classnames/bind'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import styles from './Checkbox.module.css'

export const Checkbox = ({
	type,
	name,
	variant,
	id,
	label,
	tooltip,
	subtext,
	onChange,
	onChangeBoolean,
	item,
	activeItem,
}) => {
	const handleInputChange = () => {
		if (onChangeBoolean) {
			onChangeBoolean(!item)
		} else {
			onChange(item)
		}
	}

	const variants = {
		boxed: styles.outlined,
		boxed_inline: styles.inline,
		agreement: styles.agreement,
	}

	const inputClasses = classNames(
		styles.checkbox,
		variants[variant],
		type === 'radio' ? styles.radio : ''
	)

	return (
		<>
			<div className={inputClasses}>
				<input
					type={type}
					className={styles.input}
					id={id}
					name={name}
					checked={activeItem === item}
					onChange={handleInputChange}
				/>

				<label htmlFor={id} className={styles.label}>
					{label}
				</label>

				{tooltip && (
					<>
						<div className={styles.tooltip} data-tooltip-content={tooltip}>
							<Svg type='question-circle' />
						</div>
						<Tooltip anchorSelect='[data-tooltip-content]' />
					</>
				)}
			</div>

			{subtext && isChecked ? (
				<div className={styles.subtext}>{subtext}</div>
			) : (
				''
			)}
		</>
	)
}
