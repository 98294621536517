import React from 'react'
import classNames from 'classnames/bind'
import styles from './Input.module.css'
import Svg from '../../../svgs/Svg'

export const Input = (props) => {
	const { disabled = false } = props
	const sizes = {
		lg: styles.large,
	}

	const inputClasses = classNames(
		styles.inner,
		sizes[props.size],
		props.className,
		props.icon ? styles.iconed : '',
		props.iconAlign ? styles.icon_align : ''
	)

	return (
		<>
			{props.title && <div className={styles.title}>{props.title}</div>}
			<div
				className={inputClasses}
				style={disabled ? { pointerEvents: 'none' } : {}}>
				{props.icon ? <Svg type={props.icon} /> : ''}
				<input
					style={props.style}
					type='text'
					placeholder={props.placeholder}
					className={classNames(styles.input, props.className)}
					maxLength={props.maxLength}
					ref={props.inputRef}
					value={props.inputValue}
					onChange={(e) => props.handleChange(e.target.value, props.indx)}
				/>
			</div>
		</>
	)
}
