import React from 'react'
import styles from './welcome.module.css'
import { Button } from '../../components'

import logo from '../../assets/images/logo.svg'
import { StepProgress } from '../../components';
import { Link } from 'react-router-dom';

export const Step1 = () => {
	return (
		<div className='page'>
			<Link to='/start' className={styles.skipLink}>
				Skip
			</Link>
			<StepProgress active={1} activeLast={1} />
			<div className="page-inner">
				<div className={styles.logo}>
					<img src={logo} alt='Ordinals Wallet' />
					<div className={styles.title}>
						The crypto wallet <br /> for everyone
					</div>
				</div>
			</div>
			<div className='page-inner page-align-bottom'>
				<Button to='/step2' variant='default'>
					Continue
				</Button>
			</div>
		</div>
	)
}
