import React from 'react'
import { createHashRouter } from 'react-router-dom'
import {
	Start,
	Step1,
	Step2,
	Step3,
	Import,
	Manage,
	MainManage,
	ImportAdded,
	Home,
	Send,
	Receive,
	Swap,
	Buy,
	Settings,
	Account,
	SettingsNotifications,
	SettingsCurrency,
	AccountWallets,
	BackupStart,
	Backup,
	ImportStart,
	DAppsStart,
	SettingsAbout,
	AssetInfo,
	Activity,
	SwapCoins,
	SendAmount,
	DApps,
	Rpc,
	BackupSuccess,
	MnemonicWords,
	MnemonicWordsFinish,
	CreatePin,
	ConfirmPin,
	RecoveryPhrase,
	PrivateKey,
	LoginPin,
	SendAddress,
	SendPreview,
	ImportBackup,
	Generate,
	WelcomeBack,
	SendConfirm,
} from './../pages/'
import { RouterProvider } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLockWallet, setUsePin } from '../redux/slices/StorageSlice'
import { setMenuChoose } from '../redux/slices/SettingsSlice'

export default function () {
	const dispatch = useDispatch()
	const { isLogin, lockWallet, password, autoLock, usePin, dataUser } =
		useSelector((state) => state.storage)

	React.useEffect(() => {
		dispatch(setMenuChoose(0))
		if (password !== '' && autoLock) {
			dispatch(setLockWallet(true))
		}
	}, [password, autoLock])
	const [routes, setRoutes] = React.useState(null)

	React.useEffect(() => {
		setRoutes([
			{
				path: '/',
				element:
					password !== '' && usePin && lockWallet ? (
						<WelcomeBack />
					) : isLogin && usePin == false ? (
						<Home />
					) : (
						<Step1 />
					),
			},
			{
				path: '/generate',
				element: <Generate />,
			},
			{
				path: '/step1',
				element: <Step1 />,
			},
			{
				path: '/import-backup',
				element: <ImportBackup />,
			},
			{
				path: '/welcome-back',
				element: <WelcomeBack />,
			},
			{
				path: '/send-preview',
				element: <SendPreview />,
			},
			{
				path: '/send-address',
				element: <SendAddress />,
			},
			{
				path: '/send-amount',
				element: <SendAmount />,
			},
			{
				path: '/login-pin',
				element: <LoginPin />,
			},
			{
				path: '/copy-phrase',
				element: <RecoveryPhrase />,
			},
			{
				path: '/copy-key',
				element: <PrivateKey />,
			},
			{
				path: '/create-pin',
				element: <CreatePin />,
			},
			{
				path: '/confirm-pin',
				element: <ConfirmPin />,
			},
			{
				path: '/swap-coins',
				element: <SwapCoins />,
			},
			{
				path: '/dapps',
				element: <DAppsStart />,
			},
			{
				path: '/dapps-connect',
				element: <DApps />,
			},
			{
				path: '/activity',
				element: <Activity />,
			},
			{
				path: '/assets-info',
				element: <AssetInfo />,
			},
			{
				path: '/manage-cryptos',
				element: <Manage />,
			},
			{
				path: '/manage-cryptos-main',
				element: <MainManage />,
			},
			{
				path: '/about',
				element: <SettingsAbout />,
			},
			{
				path: '/settings',
				element: <Settings />,
			},
			{
				path: '/currency',
				element: <SettingsCurrency />,
			},
			{
				path: '/import-start',
				element: <ImportStart />,
			},
			{
				path: '/wallets',
				element: <AccountWallets />,
			},
			{
				path: '/backup',
				element: <Backup />,
			},
			{
				path: '/backup-start',
				element: <BackupStart />,
			},
			{
				path: '/backup-success',
				element: <BackupSuccess />,
			},
			{
				path: '/backup-mnemonic',
				element: <MnemonicWords />,
			},
			{
				path: '/backup-finish',
				element: <MnemonicWordsFinish />,
			},
			{
				path: '/notifications',
				element: <SettingsNotifications />,
			},
			{
				path: '/start',
				element: <Start />,
			},
			{
				path: '/step2',
				element: <Step2 />,
			},
			{
				path: '/step3',
				element: <Step3 />,
			},
			{
				path: '/import',
				element: <Import />,
			},
			{
				path: '/manage',
				element: <Manage />,
			},
			{
				path: '/import-added',
				element: <ImportAdded />,
			},
			{
				path: '/home',
				element: <Home />,
			},
			{
				path: '/send',
				element: <Send />,
			},
			{
				path: '/receive',
				element: <Receive />,
			},
			{
				path: '/swap',
				element: <Swap />,
			},
			{
				path: '/buy',
				element: <Buy />,
			},
			{
				path: '/settings-account',
				element: <Account />,
			},
			{
				path: '/rpc',
				element: <Rpc />,
			},
			{
				path: '/send-confirm',
				element: <SendConfirm />,
			},
		])
	}, [isLogin])

	if (routes == null) {
		return
	}

	const router = createHashRouter(routes)

	return <RouterProvider router={router} />
}
